.fp-controlArrow.fp-prev {
  width: 20px !important;
  height: 40px;
  left: 2.19vw;

  border: 0;
  background-image: url("./arrow-left.svg");
  background-size: 20px 40px;
  background-repeat: no-repeat;
  background-position: right;
}

.fp-controlArrow.fp-next {
  width: 20px !important;
  height: 40px;
  right: 2.19vw;

  border: 0;
  background-image: url("/arrow-right.svg");
  background-size: 20px 40px;
  background-repeat: no-repeat;
  background-position: left;
}
