@media (min-width: 501px) {
  @mixin half-gray-rectangle() {
    position: relative;

    width: 100%;
    height: 100%;

    border-radius: 20px;
    background-color: #f5f5f5;

    min-width: 1206px;
    min-height: 658px;
  }

  @mixin half-video {
    position: absolute;

    max-width: 120%;
    max-height: 100%;
    width: 100.5%;
    height: 100%;

    object-fit: cover;
  }

  // 동영상 옆에 있는 설명 텍스트
  @mixin description-text() {
    position: absolute;
    bottom: 50px;

    //sass가 아닌 css에 기본 내장된 max 사용을 위해 'm'을 문자열로 처리
    font-size: #{m}ax(1.2vw, 16px);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    color: #222222;
  }

  html,
  body {
    width: 100%;
    height: 100%;

    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .wrapper {
    position: relative;

    width: 100%;
    height: 100%;
    // background-color: beige;
  }

  .top-header {
    cursor: pointer;

    position: fixed;
    z-index: 6495;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;

    text-align: center;

    background-color: #ffffff;
    border-bottom: solid 1px #f6f6f6;

    img {
      width: 110px;
      height: 30px;
    }
  }

  .page1 {
    position: relative;
    width: 100%;
    height: 100%;

    overflow: hidden;

    .video-bg {
      background-color: #222222;

      position: absolute;
      left: 0%; /* % of surrounding element */
      top: 0%;
      // object-position: center bottom;
      // transform: translate(-50%, -50%);
      // transform: translateY(60px);

      // width: 100%;
      // height: auto;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;

      object-fit: cover;

      filter: blur(1px);
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 4;
      background-color: rgba(60, 60, 60, 0.6);
    }

    .cp {
      position: absolute;
      z-index: 6495;

      left: 10%;
      top: 180px;

      font-size: 45px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #ffffff;
    }
  }

  .contact-us {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 24px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      display: flex;
      flex-direction: column;

      position: relative;

      width: 100%;
      height: 100%;

      border-radius: 20px;
      background-color: #f5f5f5;

      padding: 3.66vw;
      padding-bottom: 2.5vw;

      .section-title {
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #222222;

        margin-bottom: 120px;

        img {
          position: relative;
          top: 7px;

          width: 180px;
        }
      }

      .content-wrap {
        padding: 0 32px;

        .logos {
          margin-bottom: 30px;

          img {
            width: 30px;
            height: 30px;

            margin-right: 24px;
          }
        }

        .contact-info {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222222;
        }
      }
    }
  }

  .page15-1 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 24px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      display: flex;
      flex-direction: column;

      position: relative;

      width: 100%;
      height: 100%;

      border-radius: 20px;
      background-color: #f5f5f5;

      padding: 3.66vw;
      padding-bottom: 2.5vw;

      .section-title {
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #222222;

        margin-bottom: auto;

        img {
          position: relative;
          top: 7px;

          width: 180px;
        }
      }

      .content-wrap {
        display: flex;

        position: relative;
        bottom: 0;

        width: 100%;

        background-color: transparent;

        // 비율 유지
        margin-bottom: 0px;

        li {
          margin-bottom: 58px;
        }

        li::marker {
          transform: translateY(-20px);
        }

        .column {
          width: 50%;
          background-color: transparent;

          .title {
            font-size: 32px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            color: #222222;

            margin-top: 0;
            margin-bottom: 0;
          }

          .desc-text {
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            color: #222222;

            margin-top: 15px;
          }
        }
      }
    }
  }

  .page2-1 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 24px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      display: flex;
      flex-direction: column;

      position: relative;

      width: 100%;
      height: 100%;

      border-radius: 20px;
      background-color: #f5f5f5;

      padding: 3.66vw;

      .section-title {
        font-size: 42px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #222222;

        margin-bottom: auto;
      }

      .content-wrap {
        position: relative;
        bottom: 0;

        width: 100%;

        background-color: #979797;

        // 비율 유지
        padding-bottom: 36.52%;
        margin-bottom: 0px;

        overflow: hidden;

        video {
          position: absolute;
          z-index: 3;
          left: -2px;
          top: -2px;

          border: 0px solid rgba(0, 0, 0, 0) !important;
          outline: none;

          max-width: 110%;
          max-height: 110%;

          width: 101%;
          height: 101%;

          object-fit: cover;
        }
      }
    }
  }

  .page2-2 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 24px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      @include half-gray-rectangle();

      // padding: 3.66vw;

      .column-wrapper {
        position: relative;
        display: flex;

        width: 100%;
        height: 100%;

        .left-half {
          display: inline-block;
          position: relative;

          width: 50%;
          height: 100%;

          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video;
          }
        }

        .right-half {
          display: inline-block;

          position: relative;

          width: 50%;
          height: 100%;

          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          padding: 50px;
          padding-right: 0px;

          .section-title {
            font-size: 42px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-3 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 24px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      @include half-gray-rectangle();

      // padding: 3.66vw;

      .column-wrapper {
        position: relative;
        display: flex;

        width: 100%;
        height: 100%;

        .right-half {
          display: inline-block;
          position: relative;

          width: 50%;
          height: 100%;

          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video;
          }
        }

        .left-half {
          display: inline-block;

          position: relative;

          width: 50%;
          height: 100%;

          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          padding: 50px;

          .section-title {
            font-size: 42px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-4 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 24px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      @include half-gray-rectangle();

      .column-wrapper {
        position: relative;
        display: flex;

        width: 100%;
        height: 100%;

        .left-half {
          display: inline-block;
          position: relative;

          width: 50%;
          height: 100%;

          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          background-color: transparent;

          overflow: hidden;

          video {
            @include half-video();
          }
        }

        .right-half {
          display: inline-block;

          position: relative;

          width: 50%;
          height: 100%;

          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          padding: 50px;

          .section-title {
            font-size: 42px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-5 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 24px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      @include half-gray-rectangle();

      .column-wrapper {
        position: relative;
        display: flex;

        width: 100%;
        height: 100%;

        .right-half {
          display: inline-block;
          position: relative;

          width: 50%;
          height: 100%;

          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video();
          }
        }

        .left-half {
          display: inline-block;

          position: relative;

          width: 50%;
          height: 100%;

          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          padding: 50px;

          .section-title {
            font-size: 42px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }
}

/////////////////////////////
///////// MEDIA QUERY

@media (max-width: 600px) {
  //////////////////////////////////

  @mixin half-gray-rectangle() {
    position: relative;

    width: 100%;
    height: 100%;

    border-radius: 20px;
    background-color: #f5f5f5;

    min-width: 0;
    min-height: 0;
  }

  @mixin half-video {
    position: absolute;

    max-width: 120%;
    max-height: 100%;
    width: 100.5%;
    height: 100%;

    left: -1px;

    object-fit: cover;
  }

  // 동영상 옆에 있는 설명 텍스트
  @mixin description-text() {
    position: absolute;
    bottom: 20px;

    word-break: keep-all;
    max-width: calc(100vw - 11.85vw - 11.85vw);
    //sass가 아닌 css에 기본 내장된 max 사용을 위해 'm'을 문자열로 처리
    font-size: #{m}ax(1.2vw, 14px);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    color: #222222;
  }

  html,
  body {
    width: 100%;
    height: 100%;

    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .wrapper {
    position: relative;

    width: 100%;
    height: 100%;
  }

  .top-header {
    cursor: pointer;

    position: fixed;
    z-index: 6495;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;

    text-align: center;

    background-color: #ffffff;
    border-bottom: solid 1px #f6f6f6;

    img {
      width: 87px;
      height: 22px;
    }
  }

  .page1 {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .video-bg {
      position: absolute;
      z-index: 3;

      top: 60px;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 4;
      background-color: rgba(41, 41, 41, 0.4);
    }

    .cp {
      position: absolute;
      z-index: 100;
      left: 10%;
      top: 23.5%;

      font-size: 42px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #ffffff;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      // background-color: black;
    }
  }

  .contact-us {
    position: relative;

    width: 100%;
    height: 100%;

    padding: 18px 21px;

    .gray-rectangle {
      display: flex;
      flex-direction: column;

      position: relative;

      width: 100%;
      height: 100%;

      border-radius: 20px;
      background-color: #f5f5f5;

      padding: 6.275vw;
      padding-top: 10vw;
      padding-bottom: 2vw;

      .section-title {
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #222222;

        margin-bottom: 120px;

        img {
          position: relative;
          top: 7px;

          width: 180px;
        }
      }

      .content-wrap {
        padding: 0px;

        .logos {
          margin-bottom: 30px;

          img {
            width: 30px;
            height: 30px;

            margin-right: 20px;
          }
        }

        .contact-info {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222222;
        }
      }
    }
  }

  .page15-1 {
    position: relative;

    width: 100%;
    height: 100%;

    padding: 18px 21px;

    .gray-rectangle {
      display: flex;
      flex-direction: column;

      position: relative;

      width: 100%;
      height: 100%;

      border-radius: 20px;
      background-color: #f5f5f5;

      padding: 5vw;
      padding-bottom: 2.5vw;

      .section-title {
        word-break: keep-all;
        font-size: 21px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        color: #222222;

        margin-bottom: auto;

        img {
          position: relative;
          top: 7px;

          width: 100px;
        }
      }

      .content-wrap {
        position: relative;
        bottom: 0;

        width: 100%;

        background-color: transparent;

        // 비율 유지
        padding-bottom: 0.52%;
        margin-bottom: 0px;

        ul {
          padding-inline-start: 8vw;
        }

        li {
          margin-bottom: 18px;
        }

        li::marker {
          transform: translateY(-20px);
        }

        .column {
          background-color: transparent;

          .title {
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            color: #222222;

            margin-top: 0;
            margin-bottom: 7px;
          }

          .desc-text {
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            color: #222222;

            margin: 0;
          }
        }
      }
    }
  }

  .page2-1 {
    position: relative;

    width: 100%;
    height: 100%;

    padding: 18px 21px;

    .gray-rectangle {
      position: relative;

      width: 100%;
      height: 100%;

      border-radius: 20px;
      background-color: #f5f5f5;

      padding: 0;

      .section-title {
        height: 40%;

        font-size: 27px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        color: #222222;

        padding: 5vw 6vw;
      }

      .content-wrap {
        height: 60%;

        position: relative;
        bottom: 0;

        width: 100%;

        background-color: #979797;
        overflow: hidden;

        // 비율 유지
        padding-bottom: 0;
        margin-bottom: 0px;

        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        video {
          @include half-video;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }

        video:focus {
          outline: none;
        }
      }
    }
  }

  .page2-2 {
    position: relative;

    width: 100%;
    height: 100%;

    padding: 18px 21px;

    .gray-rectangle {
      @include half-gray-rectangle();

      // padding: 3.66vw;

      .column-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        .left-half {
          display: inline-block;
          position: relative;

          width: 100%;
          height: 40%;

          border-top-left-radius: 20px;
          border-top-right-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
          }
        }

        .right-half {
          display: inline-block;

          position: relative;

          width: 100%;
          height: 60%;

          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          padding: 6vw;

          .section-title {
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-3 {
    position: relative;

    width: 100%;
    height: 100%;

    padding: 18px 21px;

    .gray-rectangle {
      @include half-gray-rectangle();

      // padding: 3.66vw;

      .column-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        .right-half {
          display: inline-block;
          position: relative;

          width: 100%;
          height: 45%;

          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }

        .left-half {
          display: inline-block;

          position: relative;

          width: 100%;
          height: 55%;

          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          padding: 6vw;

          .section-title {
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-4 {
    position: relative;

    width: 100%;
    height: 100%;

    padding: 18px 21px;

    .gray-rectangle {
      @include half-gray-rectangle();

      // padding: 3.66vw;

      .column-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        .left-half {
          display: inline-block;
          position: relative;

          width: 100%;
          height: 45%;

          border-top-left-radius: 20px;
          border-top-right-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
          }
        }

        .right-half {
          display: inline-block;

          position: relative;

          width: 100%;
          height: 55%;

          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          padding: 6vw;

          .section-title {
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-5 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: calc(60px + 5.85vw);
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 5.85vw;

    .gray-rectangle {
      @include half-gray-rectangle();

      // padding: 3.66vw;

      .column-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        .right-half {
          display: inline-block;
          position: relative;

          width: 100%;
          height: 42%;

          border-top-left-radius: 20px;
          border-top-right-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          video {
            @include half-video;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }

        .left-half {
          display: inline-block;

          position: relative;

          width: 100%;
          height: 58%;

          padding: 6vw;

          .section-title {
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }
}
