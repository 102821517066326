@media (max-width: 500px) {
  .page15-1 {
    padding-top: 86px !important;
    padding-bottom: 0 !important;

    .m-br {
      display: block;
    }

    .gray-rectangle {
      padding: 3vw !important;
    }

    .section-title {
      word-break: keep-all;
      font-size: 21px !important;
      line-height: 1.6 !important;
      margin-bottom: 30px !important;
      padding: 10px !important;

      img {
        width: 100px !important;
      }
    }

    .content-wrap {
      flex-direction: column;

      .column {
        width: 100% !important;
      }

      ul {
        margin: 0 !important;
        margin-left: -15px !important;
      }

      li {
        margin-bottom: 20px !important;

        .title {
          font-size: 16px !important;
        }

        .desc-text {
          font-size: 14px !important;

          margin-top: 5px !important;
          margin-bottom: 10px !important;
        }
      }
    }
  }

  //////////////////////////////////

  @mixin half-gray-rectangle() {
    position: relative;

    width: 100%;
    height: 100%;

    border-radius: 20px;
    background-color: #f5f5f5;

    min-width: 1206px;
    min-height: 658px;
  }

  @mixin half-video {
    position: absolute;

    max-width: 120%;
    max-height: 100%;
    width: 100.5%;
    height: 100%;

    object-fit: cover;
  }

  // 동영상 옆에 있는 설명 텍스트
  @mixin description-text() {
    position: absolute;
    bottom: 50px;

    //sass가 아닌 css에 기본 내장된 max 사용을 위해 'm'을 문자열로 처리
    font-size: #{m}ax(1.2vw, 16px);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    color: #222222;
  }

  html,
  body {
    width: 100%;
    height: 100%;

    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .wrapper {
    position: relative;

    width: 100%;
    height: 100%;
    // background-color: beige;
  }

  .top-header {
    cursor: pointer;

    position: fixed;
    z-index: 6495;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;

    text-align: center;

    background-color: #ffffff;
    border-bottom: solid 1px #f6f6f6;

    img {
      width: 87px;
      height: 22px;
    }
  }

  .page1 {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .video-bg {
      position: absolute;
      z-index: 3;

      top: 60px;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 4;
      background-color: rgba(41, 41, 41, 0.4);
    }

    .cp {
      position: absolute;
      z-index: 100;
      left: 10%;
      top: 23.5%;

      font-size: 42px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #ffffff;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      // background-color: black;
    }
  }

  .page15-1 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 86px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      display: flex;
      flex-direction: column;

      position: relative;

      width: 100%;
      height: 100%;

      border-radius: 20px;
      background-color: #f5f5f5;

      padding: 3.66vw;
      padding-bottom: 2.5vw;

      .section-title {
        font-size: 38px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #222222;

        margin-bottom: auto;

        img {
          position: relative;
          top: 7px;

          width: 180px;
        }
      }

      .content-wrap {
        display: flex;

        position: relative;
        bottom: 0;

        width: 100%;

        background-color: transparent;

        // 비율 유지
        padding-bottom: 0.52%;
        margin-bottom: 0px;

        li {
          margin-bottom: 58px;
        }

        li::marker {
          transform: translateY(-20px);
        }

        .column {
          width: 50%;
          background-color: transparent;

          .title {
            font-size: 32px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            color: #222222;

            margin-top: 0;
            margin-bottom: 0;
          }

          .desc-text {
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            color: #222222;

            margin-top: 15px;
          }
        }
      }
    }
  }

  .page2-1 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 86px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      display: flex;
      flex-direction: column;

      position: relative;

      width: 100%;
      height: 100%;

      border-radius: 20px;
      background-color: #f5f5f5;

      padding: 0;

      .section-title {
        height: 50%;

        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #222222;

        padding: 5vw 6vw;
        margin-bottom: auto;
      }

      .content-wrap {
        height: 50%;

        position: relative;
        bottom: 0;

        width: 100%;

        background-color: #979797;

        // 비율 유지
        padding-bottom: 36.52%;
        margin-bottom: 0px;
      }
    }
  }

  .page2-2 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 86px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      @include half-gray-rectangle();

      // padding: 3.66vw;

      .column-wrapper {
        position: relative;
        display: flex;

        width: 100%;
        height: 100%;

        .left-half {
          display: inline-block;
          position: relative;

          width: 50%;
          height: 100%;

          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video;
          }
        }

        .right-half {
          display: inline-block;

          position: relative;

          width: 50%;
          height: 100%;

          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          padding: 50px;
          padding-right: 0px;

          .section-title {
            font-size: 42px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-3 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 86px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      @include half-gray-rectangle();

      // padding: 3.66vw;

      .column-wrapper {
        position: relative;
        display: flex;

        width: 100%;
        height: 100%;

        .right-half {
          display: inline-block;
          position: relative;

          width: 50%;
          height: 100%;

          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video;
          }
        }

        .left-half {
          display: inline-block;

          position: relative;

          width: 50%;
          height: 100%;

          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          padding: 50px;

          .section-title {
            font-size: 42px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-4 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 86px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      @include half-gray-rectangle();

      .column-wrapper {
        position: relative;
        display: flex;

        width: 100%;
        height: 100%;

        .left-half {
          display: inline-block;
          position: relative;

          width: 50%;
          height: 100%;

          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          background-color: transparent;

          overflow: hidden;

          video {
            @include half-video();
          }
        }

        .right-half {
          display: inline-block;

          position: relative;

          width: 50%;
          height: 100%;

          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          padding: 50px;

          .section-title {
            font-size: 42px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }

  .page2-5 {
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 86px;
    padding-left: 5.85vw;
    padding-right: 5.85vw;
    padding-bottom: 24px;

    .gray-rectangle {
      @include half-gray-rectangle();

      .column-wrapper {
        position: relative;
        display: flex;

        width: 100%;
        height: 100%;

        .right-half {
          display: inline-block;
          position: relative;

          width: 50%;
          height: 100%;

          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          background-color: #979797;

          overflow: hidden;

          video {
            @include half-video();
          }
        }

        .left-half {
          display: inline-block;

          position: relative;

          width: 50%;
          height: 100%;

          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          padding: 50px;

          .section-title {
            font-size: 42px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            color: #222222;
          }

          .description-text {
            @include description-text();
          }
        }
      }
    }
  }
}
